<template>
    <uw-content title="工单报障" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.drawerCreate.Open()">新建工单</el-button>
            <el-button type="primary" size="mini" @click="UpdateUserReceive()">批量转交</el-button>
        </template>

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.state == null ? 'primary' : 'text'" @click="search.state = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.state == 0 ? 'primary' : 'text'" @click="search.state = 0; TableSearch()">待确认</el-button>
            <el-button size="mini" :type="search.state == 1 ? 'primary' : 'text'" @click="search.state = 1; TableSearch()">进行中</el-button>
            <el-button size="mini" :type="search.state == 2 ? 'primary' : 'text'" @click="search.state = 2; TableSearch()">已完成</el-button>
            <el-button size="mini" :type="search.state == 3 ? 'primary' : 'text'" @click="search.state = 3; TableSearch()">已关闭</el-button>
            <el-button size="mini" :type="search.state == 4 ? 'primary' : 'text'" @click="search.state = 4; TableSearch()">已暂停</el-button>
            <el-button size="mini" :type="search.state == 5 ? 'primary' : 'text'" @click="search.state = 5; TableSearch()">已取消</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <!-- 工单单号&工单名称 -->
                <el-input @input="TableSearch" v-model="search.number" clearable  size="mini" placeholder="工单单号"></el-input>
                <el-input @input="TableSearch" v-model="search.name" clearable  size="mini" placeholder="工单名称"></el-input>

                <!-- 客户 -->
                <crm-customer-select v-model="search.customer_id" @onChange="TableSearch" clearable />

                <!-- 项目 -->
                <projects-select v-model="search.projects_id" @onChange="TableSearch" />

                <users-select v-model="search.user_receive_id" @onChange="TableSearch" placeholder="收单人" />
                <users-select v-model="search.user_sending_id" @onChange="TableSearch" placeholder="派单人" />

                <!-- 时间范围 -->
                <el-date-picker v-model="search.range_at" @change="TableSearch" size="mini" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">

                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">
                    
                    <!-- 复选框 -->
                    <vxe-column type="checkbox" width="40" fixed="left"></vxe-column>

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 工单类型/模板 -->
                    <vxe-column width="140" field="template.name" title="工单类型/模板" sortable></vxe-column>

                    <!-- 紧急程度 -->
                    <vxe-column width="120" field="p" title="紧急程度">
                        <template #default="{ row }">
                            <el-tag v-if="row.p == 1" size="small" effect="plain" type="danger">P1 非常重要</el-tag>
                            <el-tag v-if="row.p == 2" size="small" effect="plain" type="warning">P2 重要</el-tag>
                            <el-tag v-if="row.p == 3" size="small" effect="plain" type="primary">P3 次要</el-tag>
                            <el-tag v-if="row.p == 4" size="small" effect="plain" type="success">P4 一般</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 工单编号 -->
                    <vxe-column width="160" field="number" title="工单编号">
                        <template #default="{ row }">
                            <el-link type="primary" @click="$refs.woDrawer.Open(row.id)"><i class="el-icon-paperclip w-margin-r-5" v-if="row.files_id.length > 0"></i>{{ row.number }}</el-link><br>
                        </template>
                    </vxe-column>

                    <!-- 工单名称 -->
                    <vxe-column min-width="350" field="name" title="工单名称">
                        <template #default="{ row }">
                            <el-tag class="w-margin-r-5" size="mini" effect="dark" type="warning" v-if="row.state == 0">待确认</el-tag>
                            <el-tag class="w-margin-r-5" size="mini" effect="dark" v-if="row.state == 1">进行中</el-tag>
                            <el-tag class="w-margin-r-5" size="mini" effect="dark" type="success" v-if="row.state == 2">已完成</el-tag>
                            <el-tag class="w-margin-r-5" size="mini" effect="dark" type="info" v-if="row.state == 3">已关闭</el-tag>
                            <el-tag class="w-margin-r-5" size="mini" effect="dark" type="warning" v-if="row.state == 4">已暂停</el-tag>
                            <el-tag class="w-margin-r-5" size="mini" effect="dark" type="warning" v-if="row.state == 5">已取消</el-tag>
                            <el-tag class="w-margin-r-5" size="mini" effect="dark" type="danger" v-if="row.is_overdue">已逾期</el-tag>
                            <el-tag class="w-margin-r-5" size="mini" effect="plain" v-if="row.projects_id">项</el-tag>
                            <el-tag class="w-margin-r-5" size="mini" effect="plain" v-if="row.projects_task_id" type="warning">任</el-tag>
                            {{ row.name }}
                        </template>
                    </vxe-column>

                    <!-- 开始日期 -->
                    <vxe-column width="100" field="range_at_start" title="开始日期" sortable>
                        <template #default="{ row }">
                            <el-link type="primary">{{ row.range_at_start }}</el-link><br>
                        </template>
                    </vxe-column>

                    <!-- 截止时间 -->
                    <vxe-column width="100" field="range_at_end" title="截止时间" sortable>
                        <template #default="{ row }">
                            <el-link type="primary">{{ row.range_at_end }}</el-link><br>
                        </template>
                    </vxe-column>

                    <!-- 关联客户 -->
                    <vxe-column width="150" field="customer" title="关联客户" sortable>
                        <template #default="{ row }">
                            {{ row.customer?.name }}
                        </template>
                    </vxe-column>

                    <!-- 预计工时 -->
                    <vxe-column width="100" field="day_predict" title="预计工时/P" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" :underline="false">{{ row.day_predict }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 消耗工时 -->
                    <vxe-column width="100" field="day_consume" title="消耗工时/P" sortable>
                        <template #default="{ row }">
                            <el-link type="warning" :underline="false">{{ row.day_consume }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 工单类型/模板 -->
                    <vxe-column width="100" field="day_surplus" title="剩余工时/P" sortable>
                        <template #default="{ row }">
                            <el-link type="success" :underline="false">{{ row.day_surplus }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 收单人 -->
                    <vxe-column width="110" field="user_receive_id" title="收单人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.user_receive.avatar"> {{ row.user_receive.name[0] }} </el-avatar>
                                {{  row.user_receive.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 派单人 -->
                    <vxe-column width="110" field="user_sending_id" title="派单人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.user_sending.avatar"> {{ row.user_sending.name[0] }} </el-avatar>
                                {{  row.user_sending.name }}
                            </div>
                        </template>
                    </vxe-column>
                    
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <pm-wo-create ref="drawerCreate" @onChange="TableSearch" />
        <pm-wo-drawer ref="woDrawer" @onChange="TableSearch" />
        <pm-wo-update-relay-multiple ref="drawerUpdateUserReceive" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data() {
        return {
            // 窗口
            loading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                template_id: null,
                number: null,
                name: null,
                state: null,
                projects_id: null,
                customer_id: null,
                user_sending_id: null,
                user_receive_id: null,
                range_at: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted() {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 数据检索
        |--------------------------------------------------
        |
        */

        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/my/wo',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['user_admin', 'user_receive', 'user_sending', 'user_assist', 'template', 'customer'],
                    hidden: ['describe']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 转交工单
        |--------------------------------------------------
        |
        */

        UpdateUserReceive() {
            const ids = this.$refs.xTable.getCheckboxRecords().map(item => item.id)
            this.$refs.drawerUpdateUserReceive.Open(ids)
        }
    }
}
</script>